import { useHistory } from 'react-router-dom';

let redirectTo = '';

const useAuthRedirect = () => {
  const history = useHistory();

  const setRedirect = to => {
    redirectTo = to;
  };

  const redirectOnAuth = () => {
    if (redirectTo) {
      const to = redirectTo;
      setRedirect('');
      return history.push(to);
    }
    history.push('/');
  };

  return {
    setRedirect,
    redirectOnAuth,
  };
};

export default useAuthRedirect;
