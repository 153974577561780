import React from 'react';
import { Switch, Route, Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Button,
  Link,
  HStack,
  VStack,
  StackDivider,
  Heading,
  Grid,
  Text
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import UserAuth from 'components/user/UserAuth';
import NameAndLogout from 'components/user/NameAndLogout';
import Customers from 'components/customers/List';

// const LogoutButton = styled(Button)`
//   float: right;
//   display: block;
//   margin-right: 10px;
//   margin-top: 10px;
//   padding: 3px 10px;
// `;

function App() {
  // const logout = () => {};
  return (
    <Box textAlign='center' fontSize='xl'>
      <Grid minH='100vh' p={3}>
        {/*<ColorModeSwitcher justifySelf='flex-end' />*/}
        <VStack spacing={8}>
          <UserAuth unauthorized='signin'>
            <NameAndLogout />
            <Heading size='2xl'>Plantlife Cannabis Admin Portal</Heading>
            <HStack divider={<StackDivider borderColor='gray.200' />}>
              <Link as={RouterLink} to='/customers'>
                Manage Customers
              </Link>
            </HStack>
            <Switch>
              <Route path='/customers'>
                <Customers />
              </Route>
              <Route path='/customers/:id?'>
                <Customers />
              </Route>
              <Route path=''>
                <Heading size='lg'>Please select a navigation option</Heading>
              </Route>
            </Switch>
          </UserAuth>
        </VStack>
      </Grid>
    </Box>
  );
}

export default App;

/*
              <Logo h='40vmin' pointerEvents='none' />
              <Text>
                Edit <Code fontSize='xl'>src/App.js</Code> and save to reload.
              </Text>
              <Link
                color='teal.500'
                href='https://chakra-ui.com'
                fontSize='2xl'
                target='_blank'
                rel='noopener noreferrer'
              >
                Learn Chakra
              </Link>
*/
