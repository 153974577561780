import axios from 'axios';

import {
  CUSTOMERS_LIST_REQUEST,
  CUSTOMERS_LIST_RESPONSE,
  CUSTOMERS_LIST_ERROR,
  CUSTOMER_UPDATE_REQUEST,
  CUSTOMER_UPDATE_RESPONSE,
  CUSTOMER_UPDATE_ERROR,
  CUSTOMER_DELETE_REQUEST,
  CUSTOMER_DELETE_RESPONSE,
  CUSTOMER_DELETE_ERROR
} from 'redux/reducers/customers';

export const getCustomers = ({ page = 1, filter, sort }) => {
  return async dispatch => {
    // dispatch, getState
    dispatch({ type: CUSTOMERS_LIST_REQUEST });
    try {
      const response = await axios.get('/data/customers/list', { params: { page, filter, sort } });
      dispatch({
        type: CUSTOMERS_LIST_RESPONSE,
        data: response?.data?.customers,
        pages: response?.data?.pages || 0,
        count: response?.data?.count || 0,
        response,
        page
      });
    } catch (error) {
      dispatch({ type: CUSTOMERS_LIST_ERROR, data: error });
    }
  };
};

export const updateCustomerStatus = ({ customer, status }) => {
  return async (dispatch, getState) => {
    if (!customer || typeof status === 'undefined') {
      console.error('error, missing customer or status, cannot update');
      return;
    }
    const customers = getState()?.customers.customers;
    const myCustomer = customers.find(c => c._id === customer);
    dispatch({ type: CUSTOMER_UPDATE_REQUEST, customer });
    try {
      const response = await axios.post('/data/customers/update-status', { customer, status });
      if (response?.data?.result) {
        myCustomer.enabled = status;
      }
      dispatch({ type: CUSTOMER_UPDATE_RESPONSE, data: myCustomer, response });
    } catch (error) {
      dispatch({ type: CUSTOMER_UPDATE_ERROR, data: error });
    }
  };
};

export const deleteCustomer = ({ customer }) => {
  return async (dispatch, getState) => {
    if (!customer) {
      console.error('error, missing customer, cannot delete');
      return;
    }
    const customers = getState()?.customers.customers;
    let myCustomer = customers.find(c => c._id === customer);
    if (!myCustomer) {
      console.error('error, customer not found, cannot delete');
      return;
    }
    console.log('ok try to delete customer', customer);
    dispatch({ type: CUSTOMER_DELETE_REQUEST, customer });
    try {
      const response = await axios.post('/data/customers/delete', { customer });
      console.log('delete customer response', response?.data, myCustomer);
      if (!response?.data?.result) {
        myCustomer = null;
      }
      dispatch({ type: CUSTOMER_DELETE_RESPONSE, data: myCustomer?._id, response });
    } catch (error) {
      console.warn('error deleting, dispatch the error', error);
      dispatch({ type: CUSTOMER_DELETE_ERROR, data: error });
    }
  };
};
