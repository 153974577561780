import React from 'react';
// import { Link as RouterLink } from 'react-router-dom';
import styled from '@emotion/styled';
import { Box, Text, Button } from '@chakra-ui/react';
import useAuth from 'hooks/useAuth';

const TopRightBox = styled(Box)`
  position: absolute;
  top: 0;
  right: 8px;
  text-align: right;
`;

const NameAndLogout = () => {
  const { user, logout } = useAuth();
  return (
    <TopRightBox>
      <Text as='span' fontSize='md'>
        Hello, {user.name}
      </Text>
      <br />
      <Button size='xs' onClick={logout}>
        Logout
      </Button>
    </TopRightBox>
  );
};

export default NameAndLogout;
