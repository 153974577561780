import React, { useReducer, useMemo, useState } from 'react';
// import { Link as RouterLink } from 'react-router-dom';
import styled from '@emotion/styled';
import {
  Box,
  Button,
  ButtonGroup,
  Heading,
  Text,
  FormControl,
  FormLabel,
  Input /*, Link*/
} from '@chakra-ui/react';
import useAuth from 'hooks/useAuth';

const FormBox = styled(Box)`
  margin: 12px 0;
  padding: 12px;
  border: 1px solid #777;
  border-radius: 8px;
`;

const LoginButton = styled(Button)`
  background-color: #3e8b30;
  color: #bbff69;
`;

const CancelButton = styled(Button)`
  background-color: #eeeeff;
  color: #000;
  margin-left: 1em;
`;

const initialFormState = {
  email: '',
  storecode: '',
  password: ''
};

const UPDATE_FORM_FIELD = 'UPDATE_FORM_FIELD';
const CLEAR_FORM = 'CLEAR_FORM';

const reducerFunc = (state, action) => {
  switch (action.type) {
    case UPDATE_FORM_FIELD:
      return {
        ...state,
        [action.data.name]: action.data.value
      };
    case CLEAR_FORM:
      return { ...initialFormState };
    default:
      return state;
  }
};

const SignIn = () => {
  const [formState, formDispatcher] = useReducer(reducerFunc, { ...initialFormState });
  const { login, loginStore } = useAuth();
  const [whichForm, setWhichForm] = useState('admin');

  const okToLogin = useMemo(() => {
    return formState.email && formState.password;
  }, [formState.email, formState.password]);

  const okToLoginStore = useMemo(() => {
    return formState.storecode && formState.password;
  }, [formState.storecode, formState.password]);

  const handleSubmit = e => {
    e.preventDefault();
    if (okToLogin) {
      login(formState);
    }
  };

  const handleSubmitStore = e => {
    e.preventDefault();
    if (okToLoginStore) {
      loginStore(formState);
    }
  };

  const updateFormField = e => {
    const {
      target: { name, value }
    } = e;
    if (name) {
      formDispatcher({ type: UPDATE_FORM_FIELD, data: { name, value } });
    }
  };

  const setForm = which => {
    setWhichForm(which);
  };

  const clearForm = e => {
    e.preventDefault();
    formDispatcher({ type: CLEAR_FORM });
  };

  return (
    <Box>
      <Heading as='h1'>Plantlife Cannabis Admin Portal</Heading>
      <Text>Please sign in to continue</Text>
      <ButtonGroup>
        <Button
          onClick={() => {
            setForm('admin');
          }}
          _focus={{ outline: '0' }}
          variant={whichForm === 'admin' ? 'solid' : 'outline'}
        >
          Admin Login
        </Button>
        <Button
          onClick={() => {
            setForm('store');
          }}
          _focus={{ outline: '0' }}
          variant={whichForm === 'store' ? 'solid' : 'outline'}
        >
          Store Login
        </Button>
      </ButtonGroup>
      {whichForm === 'admin' && (
        <FormBox as='form' onSubmit={handleSubmit}>
          <FormControl mb='10px'>
            <FormLabel htmlFor='email'>Email</FormLabel>
            <Input
              id='email'
              name='email'
              type='email'
              value={formState.email}
              onChange={updateFormField}
            />
          </FormControl>
          <FormControl mb='10px'>
            <FormLabel htmlFor='password'>Password</FormLabel>
            <Input
              id='password'
              name='password'
              type='password'
              value={formState.password}
              onChange={updateFormField}
            />
          </FormControl>
          <LoginButton type='submit' disabled={!okToLogin}>
            Login
          </LoginButton>
          <CancelButton onClick={clearForm}>Cancel</CancelButton>
        </FormBox>
      )}
      {whichForm === 'store' && (
        <FormBox as='form' onSubmit={handleSubmitStore}>
          <FormControl mb='10px'>
            <FormLabel htmlFor='email'>Store Code</FormLabel>
            <Input
              id='storecode'
              name='storecode'
              type='storecode'
              value={formState.storecode}
              onChange={updateFormField}
            />
          </FormControl>
          <FormControl mb='10px'>
            <FormLabel htmlFor='password'>Password</FormLabel>
            <Input
              id='password'
              name='password'
              type='password'
              value={formState.password}
              onChange={updateFormField}
            />
          </FormControl>
          <LoginButton type='submit' disabled={!okToLoginStore}>
            Login
          </LoginButton>
          <CancelButton onClick={clearForm}>Cancel</CancelButton>
        </FormBox>
      )}
    </Box>
  );
};

SignIn.defaultProps = {};

export default SignIn;
